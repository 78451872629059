import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import EventForm from '../components/forms/EventForm'
import config from '../../config'
import Layout from '../components/Layout'

const AkcjaSprzatanie = () => (
  <>

  <Layout>
  <Helmet
    htmlAttributes={{
      lang: `en-en`,
    }}

    title={'Clean up action - Open to the environment - vol.III'}
    meta={[
      {
        name: `description`,
        content: config.siteDescription,
      },
      {
        name: `viewport`,
        content: `width=device-width, initial-scale=1`,
      },
      {
        name: `msapplication-TileColor`,
        content: config.themeColor,
      },
      {
        name: `theme-color`,
        content: config.themeColor,
      },
    ]}
    link={[
      {
        rel: `apple-touch-icon`,
        href: `/icons/apple-touch-icon.png`,
        sizes: `180x180`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-32x32.png`,
        sizes: `32x32`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-16x16.png`,
        sizes: `16x16`,
      },
      {
        rel: `mask-icon`,
        href: `/icons/safari-pinned-tab.svg`,
        color: config.themeColor,
      },
      {
        rel: `preconnect`,
        href: `https://fonts.gstatic.com`,
      },
      {
        rel: `stylesheet`,
        href: `https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap`,
      },
    ]}
  > </Helmet>
  <div style={{fontFamily:'system-ui',marginLeft:'20px',marginBottom:'-50px'}}>
  <br />
  <br />
  <img src="/img/ArtOpen-green.svg" alt="Art Open advertising agency" style={{maxWidth:'300px'}} />
  </div>
  <div id="sprzatanie" style={{height:'85%',width:'100%',backgroundImage:'url(/img/akcja-sprzatanie-3.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',fontFamily:'Poppins, sans-serif !important'}}>
  </div>

  <div id="action" style={{textAlign:'center',color:'#23C495',fontSize:'3.5em',width:'50%',marginLeft:'25%',fontVariant:'all-petite-caps',letterSpacing: '0.3em'}}>
  <h1 style={{fontWeight:'900',fontVariant:'all-small-caps',fontFamily:'system-ui',wordBreak:'break-word'}}>Clean up action</h1>
  <h2 style={{fontWeight:'400',marginTop:'-20px',fontVariant:'all-small-caps',fontSize:'.8em',wordBreak:'break-word'}}>contest rules</h2>
  <hr style={{backgroundColor:'#23C495',marginTop:'0px'}} />
  <h3 style={{fontWeight:'900',marginTop:'-20px',fontVariant:'contextual',fontFamily:'system-ui'}}> vol. III </h3>
  </div>

  <div style={{paddingTop:'10px',paddingLeft:'10%',paddingRight:'10%',textAlign:'left'}}>


  <div style={{padding:'2% 10% 5% 10%'}}>

<h2 className="title inline-link">  CONTEST RULES  </h2>

<h2 className="subtitle" style={{textAlign:'center',fontWeight:'900'}}><span className="inline-link">§1</span> <br />GENERAL PROVISIONS</h2>
<p>
1. The contest is organised by Art Open Sp. z o.o.<br />
2. The contest will be held during the Clean Up Vol. III campaign.<br />
3. For every full bag of rubbish - 1 point will be awarded.<br />
4. For each bulky reuse found and moved to the place indicated by the organizer - 3 points will be awarded.<br />
5. Each collected point will be immediately exchanged for a sticker provided to the participant by their team leader. 6. The competition is not a promotional lottery within the meaning of Art. 2 sec. 1 point 9 of the Act on games and mutual wagering of July 29, 1992. (Journal of Laws 04.4.27 as amended) and is not a subject to the rules contained in the above-mentioned Act and executive regulations to this Act.<br />
</p><br />
<h2 className="subtitle" style={{textAlign:'center',fontWeight:'900'}}><span className="inline-link">§2</span><br />
CONDITIONS FOR PARTICIPATION IN THE CONTEST</h2>
<p>
1. A participant of the competition may be any natural person who registered as a participant of the Cleaning Action Vol. III.<br />
2. To participate in the contest, the following conditions must be met together:<br />
a) reading the contest rules on the website <Link className="inline-link" to="/">artopen.co</Link><br />
b) fulfilment of the conditions set out in §3 p.1<br />
</p>
<br />
<h2 className="subtitle" style={{textAlign:'center',fontWeight:'900'}}><span className="inline-link">§3</span><br />
CONTEST RULES AND PRIZES</h2>
<p>
1. The object of the contest is to collect as many points as possible.<br />
2. The award will be granted to the team that collects the largest number of stickers on the space prepared to store them. In order to determine the final number of points, the sum of points from the badges of all team members will be divided by the number of people in the team.<br />
3. The prizes in the contest are:<br /><br />
</p>
<p style={{textAlign:'center',fontWeight:'900'}}>
I PLACE - information soon<br /> II PLACE - information soon<br /> III PLACE - information soon<br /><br />
</p>
<p>
4. All doubts regarding the rules of the contest, its provisions and interpretations shall be resolved by<br />
the Organiser.<br />
5. Prize winners will be selected at the Afterparty at the Hot Spot Town Beach.<br />
6. In order to confirm the number of stickers gathered, participants will be asked to provide a badge with points stuck on as a form of evidence.<br />
</p>
<br />
<h2 className="subtitle" style={{textAlign:'center',fontWeight:'900'}}><span className="inline-link">§4</span><br />
PERSONAL DATA OF PARTICIPANTS IN THE CONTEST</h2>
<p>
1. In order to participate in the competition, the participant must provide true personal data, i.e.: name and surname.<br />
2. The data provided will be processed solely for the purpose of running this competition and in connection with issuing the prizes.<br />
</p><br />
<h2 className="subtitle" style={{textAlign:'center',fontWeight:'900'}}><span className="inline-link">§5</span><br />
FINAL PROVISIONS</h2>
<p>
The organizer is entitled to change the provisions of the regulations, provided that the changes doesn`t deteriorate the conditions of participation in the competition. In particular, this refers to changes in the dates of individual competition activities and changes to the specification of the award. The amended regulations have been in force since their publication on <Link className="inline-link" to="/">artopen.co</Link>.</p>




  </div>






  </div>







  <div style={{textAlign:'center',paddingBottom:'50px'}}>
    <Link className='button-green' to="/akcja-sprzatanie/"> return to registration </Link>
  </div>
  <br />


  </Layout>
  </>

)


export default AkcjaSprzatanie
